var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _vm.importing
        ? _c(
            "div",
            [
              _c("v-file-input", {
                on: { change: _vm.importXlsx },
                model: {
                  value: _vm.uploadedFile,
                  callback: function ($$v) {
                    _vm.uploadedFile = $$v
                  },
                  expression: "uploadedFile",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.uploadedFile
        ? _c(
            "div",
            [_c("ImportEditor", { attrs: { file: _vm.uploadedFile } })],
            1
          )
        : _c(
            "card",
            {
              attrs: { title: _vm.$t("models.company.selves") },
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: { small: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.xls()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("fa fa-download"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("actions.export")) + " "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-2",
                          attrs: { small: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.importing = true
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("fa fa-upload"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("actions.import")) + " "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.createCompany()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "", small: "" } }, [
                            _vm._v("fa fa-plus"),
                          ]),
                          _vm._v(" " + _vm._s(_vm.$t("actions.create")) + " "),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c("grid-view", {
                ref: "grid",
                attrs: {
                  headers: _vm.headers,
                  endpoint:
                    "company?expand=country,legalTypology,companyActivities.activity.sector",
                  model: "company",
                },
                on: { "data-loaded": _vm.handleDataLoaded },
                scopedSlots: _vm._u([
                  {
                    key: "item.enabled",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.enabled
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "green" } },
                              [_vm._v("fa fa-check")]
                            )
                          : _vm._e(),
                        !item.enabled
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "red" } },
                              [_vm._v("fa fa-times")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm.isTruncated(item.name)
                          ? _c(
                              "span",
                              [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "", color: "black" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "span",
                                                _vm._g(
                                                  _vm._b(
                                                    {},
                                                    "span",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.truncateText(
                                                          item.name
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v(_vm._s(item.name))])]
                                ),
                              ],
                              1
                            )
                          : _c("span", [_vm._v(" " + _vm._s(item.name) + " ")]),
                      ]
                    },
                  },
                  {
                    key: "item.companyActivities",
                    fn: function (ref) {
                      var item = ref.item
                      return _vm._l(item.companyActivities, function (ca) {
                        return _c(
                          "span",
                          { key: "ca-" + ca, staticStyle: { cursor: "help" } },
                          [
                            _c(
                              "span",
                              {
                                attrs: {
                                  title: _vm.translate(ca.activity.name),
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.translate(ca.activity.sector.name))
                                ),
                              ]
                            ),
                          ]
                        )
                      })
                    },
                  },
                  {
                    key: "item.country.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " + _vm._s(_vm.translate(item.country.name)) + " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.legalTypology.name",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _vm._v(
                          " " +
                            _vm._s(_vm.translate(item.legalTypology.name)) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "item.availableYearsFull",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.availableYearsFull.length === 0
                          ? [_c("i", [_vm._v("no data")])]
                          : item.availableYearsFull.length === 1
                          ? [_vm._v(_vm._s(item.availableYearsFull[0]))]
                          : [
                              _vm._v(
                                _vm._s(item.availableYearsFull[0]) +
                                  " — " +
                                  _vm._s(
                                    item.availableYearsFull[
                                      item.availableYearsFull.length - 1
                                    ]
                                  ) +
                                  " "
                              ),
                            ],
                      ]
                    },
                  },
                  {
                    key: "item.availableYearsSmart",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.availableYearsSmart.length === 0
                          ? [_c("i", [_vm._v("no data")])]
                          : item.availableYearsSmart.length === 1
                          ? [_vm._v(_vm._s(item.availableYearsSmart[0]))]
                          : [
                              _vm._v(
                                _vm._s(item.availableYearsSmart[0]) +
                                  " — " +
                                  _vm._s(
                                    item.availableYearsSmart[
                                      item.availableYearsSmart.length - 1
                                    ]
                                  ) +
                                  " "
                              ),
                            ],
                      ]
                    },
                  },
                  {
                    key: "item.web",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        item.web
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "green" } },
                              [_vm._v("fa fa-check")]
                            )
                          : _vm._e(),
                        !item.web
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "red" } },
                              [_vm._v("fa fa-times")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "item._actions",
                    fn: function (ref) {
                      var item = ref.item
                      return [
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.viewCompany(item)
                              },
                            },
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v("mdi-eye"),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { icon: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.activateCompany(item)
                              },
                            },
                          },
                          [
                            item.enabled
                              ? _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fa fa-unlock-alt"),
                                ])
                              : _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fa fa-lock"),
                                ]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.editDialog,
            callback: function ($$v) {
              _vm.editDialog = $$v
            },
            expression: "editDialog",
          },
        },
        [
          _vm.editDialog && _vm.editedCompany
            ? _c("Form", {
                attrs: { companyId: _vm.editedCompany.id },
                on: { save: _vm.onDialogSave },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }