var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "mb-2" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "card",
                [
                  _c("div", { staticClass: "card-header" }, [
                    _c("div", { staticClass: "title-and-button" }, [
                      _c(
                        "div",
                        {
                          staticClass: "card-title selected-item",
                          on: {
                            click: function ($event) {
                              return _vm.viewIndex()
                            },
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              "Portefeuille : " +
                                _vm._s(_vm.portfolioName) +
                                " ( " +
                                _vm._s(_vm.portfolioDevise) +
                                ")"
                            ),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  multiple: "",
                                  items: _vm.countries,
                                  label: _vm.$t("models.country.self"),
                                  "item-text": function (item) {
                                    return item.name != "null" &&
                                      item.code != null
                                      ? _vm.translate(item.name) +
                                          ": (" +
                                          item.code +
                                          ")"
                                      : "" + _vm.translate(item.name)
                                  },
                                  "item-value": "id",
                                },
                                on: { change: _vm.onChangeCountry },
                                model: {
                                  value: _vm.countryFilterId,
                                  callback: function ($$v) {
                                    _vm.countryFilterId = $$v
                                  },
                                  expression: "countryFilterId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  multiple: "",
                                  items: _vm.sectors,
                                  label: _vm.$t("models.sector.self"),
                                  "item-text": function (item) {
                                    return item.name != "null" &&
                                      item.domain != null
                                      ? _vm.translate(item.name) +
                                          ": (" +
                                          item.domain +
                                          ")"
                                      : "" + _vm.translate(item.domain)
                                  },
                                  "item-value": "id",
                                },
                                on: { change: _vm.onChangeSector },
                                model: {
                                  value: _vm.sectorId,
                                  callback: function ($$v) {
                                    _vm.sectorId = $$v
                                  },
                                  expression: "sectorId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  items: _vm.indices,
                                  label: _vm.$t("stock.title"),
                                  "item-text": function (item) {
                                    return item.name
                                  },
                                  "item-value": "stock_exchange_id",
                                },
                                model: {
                                  value: _vm.indexId,
                                  callback: function ($$v) {
                                    _vm.indexId = $$v
                                  },
                                  expression: "indexId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-subheader", { staticClass: "pl-0" }, [
                                _vm._v(" score MSI range "),
                              ]),
                              _c("v-range-slider", {
                                attrs: {
                                  max: "200",
                                  min: "0",
                                  "thumb-label": "",
                                  "thumb-size": 26,
                                },
                                model: {
                                  value: _vm.scoreRange,
                                  callback: function ($$v) {
                                    _vm.scoreRange = $$v
                                  },
                                  expression: "scoreRange",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                              attrs: { cols: "4" },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "pl-0" },
                                        [_vm._v(" solidity range ")]
                                      ),
                                      _c("v-range-slider", {
                                        attrs: {
                                          max: "100",
                                          min: "0",
                                          "thumb-label": "",
                                          "thumb-size": 26,
                                          filled: "",
                                        },
                                        model: {
                                          value: _vm.solidityRange,
                                          callback: function ($$v) {
                                            _vm.solidityRange = $$v
                                          },
                                          expression: "solidityRange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v("Gestion des liquidités"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.liquidity.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.liquidity,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "filters.liquidity.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.liquidity.operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.liquidity,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.liquidity.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [_c("h4", [_vm._v("Gestion des actifs")])]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.actifs.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.actifs,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "filters.actifs.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.actifs.operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.actifs,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression: "filters.actifs.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [_c("h4", [_vm._v("Gestion des passifs")])]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.passifs.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.passifs,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "filters.passifs.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.passifs.operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.passifs,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.passifs.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [_c("h4", [_vm._v("Gestion des risques")])]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.risques.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.risques,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "filters.risques.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.risques.operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.risques,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.risques.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider", {
                        staticStyle: { color: "#ffffff", "margin-top": "20px" },
                      }),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center",
                              attrs: { cols: "4" },
                            },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-subheader",
                                        { staticClass: "pl-0" },
                                        [_vm._v(" performance range ")]
                                      ),
                                      _c("v-range-slider", {
                                        attrs: {
                                          max: "100",
                                          min: "0",
                                          "thumb-label": "",
                                          "thumb-size": 26,
                                        },
                                        model: {
                                          value: _vm.performanceRange,
                                          callback: function ($$v) {
                                            _vm.performanceRange = $$v
                                          },
                                          expression: "performanceRange",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "8" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v("Rentabilité commerciale"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value: _vm.filters.rentabilite.value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.rentabilite,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite.operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters.rentabilite,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v("Rentabilité économique"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite_economique
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters
                                                .rentabilite_economique,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite_economique.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite_economique
                                              .operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters
                                                .rentabilite_economique,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite_economique.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v("Rentabilité financière"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite_financiere
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters
                                                .rentabilite_financiere,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite_financiere.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite_financiere
                                              .operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters
                                                .rentabilite_financiere,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite_financiere.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("h4", [
                                        _vm._v("Rentabilité d'exploitation"),
                                      ]),
                                    ]
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-center",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-text-field", {
                                        attrs: {
                                          label: "value to compare",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite_exploitation
                                              .value,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters
                                                .rentabilite_exploitation,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite_exploitation.value",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "d-flex justify-content-center align-items-end",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.operators,
                                          label: "Operator",
                                          "hide-details": "auto",
                                        },
                                        model: {
                                          value:
                                            _vm.filters.rentabilite_exploitation
                                              .operator,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.filters
                                                .rentabilite_exploitation,
                                              "operator",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "filters.rentabilite_exploitation.operator",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.loadingData
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" },
                              }),
                              _vm._v(" loading data .. "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "card",
                        { attrs: { title: _vm.$t("company.title") } },
                        [
                          _c("grid-view", {
                            ref: "grid",
                            attrs: {
                              headers: _vm.headers,
                              endpoint: _vm.endpoint,
                              "single-select": false,
                              "sort-by": ["score"],
                              "sort-desc": [true],
                              model: "company",
                              "show-select": "",
                            },
                            on: { "selection-change": _vm.onSelectionChange },
                            scopedSlots: _vm._u([
                              {
                                key: "item.country.name",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.translate(item.country.name)
                                        ) +
                                        " "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "item.web",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    item.web
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: "green",
                                            },
                                          },
                                          [_vm._v("fa fa-check")]
                                        )
                                      : _vm._e(),
                                    !item.web
                                      ? _c(
                                          "v-icon",
                                          {
                                            attrs: { small: "", color: "red" },
                                          },
                                          [_vm._v("fa fa-times")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                              {
                                key: "item._actions",
                                fn: function (ref) {
                                  var item = ref.item
                                  return [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { icon: "", small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.viewCompany(item)
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", { attrs: { small: "" } }, [
                                          _vm._v("mdi-eye"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                _vm._l(
                                  this.composition,
                                  function (comp, index) {
                                    return _c(
                                      "v-col",
                                      { key: comp.id, attrs: { cols: "12" } },
                                      [
                                        _c(
                                          "v-row",
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly: _vm.fmp,
                                                    label: _vm.$t("close"),
                                                  },
                                                  model: {
                                                    value: comp.close,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "close",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "comp.close",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly: _vm.fmp,
                                                    label: _vm.$t("name"),
                                                  },
                                                  model: {
                                                    value: comp.name,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "name",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "comp.name",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly: "",
                                                    label: _vm.$t("devise"),
                                                  },
                                                  model: {
                                                    value:
                                                      comp.company_currency,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "company_currency",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "comp.company_currency",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly: "",
                                                    label: _vm.$t("score"),
                                                  },
                                                  model: {
                                                    value: comp.score,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "score",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "comp.score",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly: "",
                                                    label: _vm.$t("Pays"),
                                                  },
                                                  model: {
                                                    value: comp.country,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "country",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "comp.country",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "2" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    disabled: !comp.company_id,
                                                    label: _vm.$t("Nbr action"),
                                                  },
                                                  on: {
                                                    blur: function ($event) {
                                                      return _vm.updateNbrAction(
                                                        index
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: comp.nbr_action,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "nbr_action",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "comp.nbr_action",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "1" } },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    readonly: "",
                                                    label: _vm.$t("total"),
                                                  },
                                                  model: {
                                                    value: comp.valo,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        comp,
                                                        "valo",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "comp.valo",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "d-flex justify-center align-items-center",
                                                attrs: { cols: "1" },
                                              },
                                              [
                                                _c(
                                                  "v-btn",
                                                  {
                                                    staticClass: "ma-2",
                                                    attrs: {
                                                      disabled:
                                                        !comp.valo ||
                                                        comp.valo <= 0 ||
                                                        !comp.company_currency,
                                                      small: "",
                                                      color: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.addCompany(
                                                          comp,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      {
                                                        attrs: {
                                                          left: "",
                                                          dark: "",
                                                        },
                                                      },
                                                      [_vm._v("fa fa-plus")]
                                                    ),
                                                    _vm._v(" Ajouter "),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                              _c("v-row", [
                                _vm.loadingData
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          display: "inline-block",
                                        },
                                      },
                                      [
                                        _c("v-progress-circular", {
                                          attrs: { indeterminate: "" },
                                        }),
                                        _vm._v(" loading data .. "),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("upload-error", { attrs: { error: _vm.error } }),
                      _c("v-simple-table", [
                        _c("thead", [
                          _c("tr", [
                            _c("th", [_vm._v("Name")]),
                            _c("th", [_vm._v("Action")]),
                            _c("th", [_vm._v("Close")]),
                            _c("th", [_vm._v("Valorisation")]),
                            _c("th", [_vm._v("Global")]),
                            _c("th", [_vm._v("Solidité")]),
                            _c("th", [_vm._v("Performance")]),
                            _c("th", [_vm._v("%")]),
                          ]),
                        ]),
                        _c(
                          "tbody",
                          [
                            _vm._l(_vm.selectedCompanies, function (comp) {
                              return _c("tr", { key: comp.id }, [
                                _c(
                                  "td",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: {
                                          "align-items": "flex-start",
                                        },
                                        attrs: { icon: "", small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteCompany(comp.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { "x-small": "" } },
                                          [_vm._v("fa fa-trash")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticStyle: {
                                          "align-items": "flex-start",
                                        },
                                        attrs: { icon: "", small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editCompany(comp.id)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { "x-small": "" } },
                                          [_vm._v("mdi-pencil")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.truncatedName(comp.name)) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                                !comp.editing
                                  ? _c("td", { staticClass: "selected-item" }, [
                                      _vm._v(_vm._s(comp.nbraction)),
                                    ])
                                  : _c(
                                      "td",
                                      { staticClass: "selected-item" },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: _vm.$t("Nbr action"),
                                          },
                                          on: {
                                            blur: function ($event) {
                                              return _vm.editNbrAction(comp)
                                            },
                                          },
                                          model: {
                                            value: comp.nbraction,
                                            callback: function ($$v) {
                                              _vm.$set(comp, "nbraction", $$v)
                                            },
                                            expression: "comp.nbraction",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                _c("td", { staticClass: "selected-item" }, [
                                  _vm._v(_vm._s(comp.close)),
                                ]),
                                _c("td", { staticClass: "selected-item" }, [
                                  _vm._v(_vm._s(comp.valo)),
                                ]),
                                _c("td", { staticClass: "selected-item" }, [
                                  _vm._v(_vm._s(comp.global)),
                                ]),
                                _c("td", { staticClass: "selected-item" }, [
                                  _vm._v(_vm._s(comp.solidite)),
                                ]),
                                _c("td", { staticClass: "selected-item" }, [
                                  _vm._v(_vm._s(comp.performance)),
                                ]),
                                _c("td", { staticClass: "selected-item" }, [
                                  _vm._v(_vm._s(comp.percentage)),
                                ]),
                              ])
                            }),
                            _vm.selectedCompanies.length == 0
                              ? _c("tr", [
                                  _c("td", [_vm._v(" No data available ! ")]),
                                  _c("td"),
                                ])
                              : _vm._e(),
                          ],
                          2
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-row",
                        { staticClass: "mt-3" },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  value: _vm.total,
                                  step: ".3",
                                  type: "number",
                                  label: _vm.$t("total portefeuille"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  value: _vm.scoreMoy.toFixed(3),
                                  step: ".3",
                                  type: "number",
                                  label: _vm.$t("Moyenne Score"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  value: _vm.soliditeMoy.toFixed(3),
                                  step: ".3",
                                  type: "number",
                                  label: _vm.$t("Moyenne solidite"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "3" } },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  readonly: "",
                                  value: _vm.performanceMoy.toFixed(3),
                                  step: ".3",
                                  type: "number",
                                  label: _vm.$t("Moyenne performance"),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.uploadingData
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [
                              _c("v-progress-circular", {
                                attrs: { indeterminate: "" },
                              }),
                              _vm._v(" Parsing data .. "),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.doneUpload
                        ? _c(
                            "div",
                            { staticStyle: { display: "inline-block" } },
                            [_vm._v(" Done ")]
                          )
                        : _vm._e(),
                      _c(
                        "v-row",
                        [
                          !_vm.exist
                            ? _c(
                                "v-col",
                                { attrs: { cols: "4", sm: "6", md: "4" } },
                                [
                                  _c(
                                    "v-menu",
                                    {
                                      ref: "menu",
                                      attrs: {
                                        "close-on-content-click": false,
                                        "return-value": _vm.startVariation,
                                        transition: "scale-transition",
                                        "offset-y": "",
                                        "min-width": "auto",
                                      },
                                      on: {
                                        "update:returnValue": function (
                                          $event
                                        ) {
                                          _vm.startVariation = $event
                                        },
                                        "update:return-value": function (
                                          $event
                                        ) {
                                          _vm.startVariation = $event
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          label:
                                                            "Date début variation",
                                                          "prepend-icon":
                                                            "mdi-calendar",
                                                          readonly: "",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.startVariation,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.startVariation =
                                                              $$v
                                                          },
                                                          expression:
                                                            "startVariation",
                                                        },
                                                      },
                                                      "v-text-field",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  )
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2076500972
                                      ),
                                      model: {
                                        value: _vm.menu,
                                        callback: function ($$v) {
                                          _vm.menu = $$v
                                        },
                                        expression: "menu",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-date-picker",
                                        {
                                          attrs: {
                                            "no-title": "",
                                            scrollable: "",
                                          },
                                          model: {
                                            value: _vm.startVariation,
                                            callback: function ($$v) {
                                              _vm.startVariation = $$v
                                            },
                                            expression: "startVariation",
                                          },
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.$refs.menu.save(
                                                    (_vm.startVariation = null)
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Clear ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.menu = false
                                                },
                                              },
                                            },
                                            [_vm._v(" Cancel ")]
                                          ),
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                text: "",
                                                color: "primary",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.$refs.menu.save(
                                                    _vm.startVariation
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" OK ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.exist
                            ? _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    display: "grid",
                                    "align-items": "center",
                                  },
                                  attrs: { cols: "8" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        disabled:
                                          _vm.selectedCompanies.length === 0,
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadData()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", dark: "" } },
                                        [_vm._v(" mdi-upload ")]
                                      ),
                                      _vm._v(" Enregistrer "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.exist
                            ? _c(
                                "v-col",
                                {
                                  staticStyle: {
                                    display: "grid",
                                    "align-items": "center",
                                  },
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        disabled:
                                          _vm.selectedCompanies.length === 0 ||
                                          _vm.uploadingData,
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.uploadData()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { left: "", dark: "" } },
                                        [_vm._v(" mdi-upload ")]
                                      ),
                                      _vm._v(" Enregistrer "),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }