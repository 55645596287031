<template>
  <v-container fluid>
    <div v-if="importing">
      <v-file-input v-model="uploadedFile" @change="importXlsx"></v-file-input>
    </div>

    <div v-if="uploadedFile">
      <ImportEditor :file="uploadedFile" />
    </div>

    <card v-else :title="$t('models.company.selves')">
      <template v-slot:actions>
        <v-btn small color="primary" @click="xls()" class="ma-2">
          <v-icon left small>fa fa-download</v-icon>
          {{ $t("actions.export") }}
        </v-btn>
        <v-btn small color="primary" @click="importing = true" class="ma-2">
          <v-icon left small>fa fa-upload</v-icon>
          {{ $t("actions.import") }}
        </v-btn>
        <v-btn small color="primary" @click="createCompany()">
          <v-icon left small>fa fa-plus</v-icon>
          {{ $t("actions.create") }}
        </v-btn>
      </template>

      <grid-view ref="grid" :headers="headers"
        endpoint="company?expand=country,legalTypology,companyActivities.activity.sector" model="company"
        @data-loaded="handleDataLoaded">
        <template v-slot:item.enabled="{ item }">
          <v-icon v-if="item.enabled" small color="green">fa fa-check</v-icon>
          <v-icon v-if="!item.enabled" small color="red">fa fa-times</v-icon>
        </template>
        <template v-slot:item.name="{ item }">
          <span v-if="isTruncated(item.name)">
            <v-tooltip bottom color="black">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ truncateText(item.name) }}
                </span>
              </template>
              <span>{{ item.name }}</span> <!-- Tooltip content -->
            </v-tooltip>
          </span>
          <span v-else>
            {{ item.name }} <!-- Full text if not truncated -->
          </span>
        </template>
        <template v-slot:item.companyActivities="{ item }">
          <span v-for="ca in item.companyActivities" :key="`ca-${ca}`" style="cursor: help">
            <span :title="translate(ca.activity.name)">{{
              translate(ca.activity.sector.name)
            }}</span>
          </span>
        </template>

        <template v-slot:item.country.name="{ item }">
          {{ translate(item.country.name) }}
        </template>

        <template v-slot:item.legalTypology.name="{ item }">
          {{ translate(item.legalTypology.name) }}
        </template>

        <template v-slot:item.availableYearsFull="{ item }">
          <template v-if="item.availableYearsFull.length === 0"><i>no data</i></template>
          <template v-else-if="item.availableYearsFull.length === 1">{{
            item.availableYearsFull[0]
          }}</template>
          <template v-else>{{ item.availableYearsFull[0] }} &mdash;
            {{ item.availableYearsFull[item.availableYearsFull.length - 1] }}
          </template>
        </template>
        <template v-slot:item.availableYearsSmart="{ item }">
          <template v-if="item.availableYearsSmart.length === 0"><i>no data</i></template>
          <template v-else-if="item.availableYearsSmart.length === 1">{{
            item.availableYearsSmart[0]
          }}</template>
          <template v-else>{{ item.availableYearsSmart[0] }} &mdash;
            {{ item.availableYearsSmart[item.availableYearsSmart.length - 1] }}
          </template>
        </template>

        <template v-slot:item.web="{ item }">
          <v-icon v-if="item.web" small color="green">fa fa-check</v-icon>
          <v-icon v-if="!item.web" small color="red">fa fa-times</v-icon>
        </template>

        <template v-slot:item._actions="{ item }">
          <v-btn @click="viewCompany(item)" icon small>
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
          <v-btn @click="activateCompany(item)" icon small>
            <v-icon v-if="item.enabled" small>fa fa-unlock-alt</v-icon>
            <v-icon v-else small>fa fa-lock</v-icon>
          </v-btn>
        </template>
        <!-- Render company name and ISIN together -->
      </grid-view>
    </card>

    <v-dialog v-model="editDialog" max-width="600">
      <Form v-if="editDialog && editedCompany" :companyId="editedCompany.id" @save="onDialogSave" />
    </v-dialog>
  </v-container>
</template>

<script>
import { Vue, Component } from "vue-property-decorator";
import Form from "@/pages/company/Form";
import formatter from "@/mixins/formatter";
import { Export } from "@/services/xlsx.ts";
import ImportEditor from "@/components/ImportEditor";
import GridView from "@/components/GridView";
import Card from "@/components/Card";
import { setPageTitle } from "@/utils/meta";

@Component({
  components: { Card, GridView, Form, ImportEditor },
  mixins: [formatter],
})
export default class CompanyIndex extends Vue {
  created() {
    setPageTitle("Companies");
  }

  editDialog = false;
  editedCompany = null;
  uploadedFile = null;
  companies = []
  importing = false;

  get headers() {
    return [
      { text: "", value: "_actions" },
      { text: this.$i18n.t("company.denomination"), value: "name", width: "100px" },
      {
        text: this.$i18n.t("ISIN"),
        value: "profil_fmp.isin",
        width: 1,
      },
      {
        text: this.$i18n.t("models.company.country"),
        value: "country.name",
        width: 1,
      },
      {
        text: this.$i18n.t("availableYearsFull"),
        value: "availableYearsFull",
        width: 190,
      },
      {
        text: this.$i18n.t("availableYearsSmart"),
        value: "availableYearsSmart",
        width: 190,
      },
      {
        text: this.$i18n.t("sectorsOfActivity.title"),
        value: "companyActivities",
      },

    ];
  }
  handleDataLoaded(data) {
    this.companies = data; // Update the companies data with the received data from GridView
  }
  viewCompany(company) {
    this.$router.push(`/company/${company.id}`);
  }
  activateCompany(company) {
    let active = 'désactiver';
    let activeVal = 1;
    if (company.enabled == 1) {
      activeVal = 0;
      active = 'désactiver';
    } else {
      activeVal = 1;
      active = 'activer';
    }

    this.$root.$confirm
      .open(
        "Activation portfolio",
        "Êtes-vous sûr(e) de vouloir " + active + " cette société ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api.post(`company/active-company?id=${company.id}&status=${activeVal}`).
            then(response => {
              this.$refs.grid.getModels();
            }).catch((e) => {
              this.error = e.response.data;
            })
        }
      });
  }
  createCompany() {
    this.$router.push("/company/create");
  }

  truncateText(text, length = 30) {
    console.log("text", text)
    if (text.length <= length) {
      return text;
    }
    return text.slice(0, length) + '...';
  }

  isTruncated(text, length = 30) {
    return text.length > length;
  }

  deleteCompany(company) {
    this.$root.$confirm
      .open(
        "Delete company",
        "Are you sure you want to delete this company ?",
        {}
      )
      .then(confirm => {
        if (confirm) {
          this.$api
            .delete(`company/${company.id}`)
            .then(() => {
              this.$refs.grid.getModels();
            })
            .catch(() => {
              alert("Unable to remove company");
            });
        }
      });
  }

  onDialogSave(company) {
    this.$refs.grid.getModels(company.id);
    this.editDialog = false;
    this.editedCompany = null;
  }

  importXlsx() {
    console.log(this.uploadedFile);
  }

  xls() {
    console.log("companies", this.companies)
    Export("company", this.companies, "companies.xlsx");
  }
}
</script>
