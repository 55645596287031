





























































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import UploadError from "@/components/UploadError.vue";
import { setPageTitle } from "@/utils/meta";
import Card from "@/components/Card.vue";
import formatter from "@/mixins/formatter";
import { PortfolioComposition } from "@/smartmsi";

@Component({
    components: { Card, UploadError },
    mixins: [formatter],
})
export default class PortefeuilleSetting extends Vue {
    @Prop() portfolioId!: number;
    @Prop() portfolioName!: string;
    @Prop() portfolioDevise!: string;
    // composition: Partial<PortfolioComposition>[] = [];
    error = null;
    errorMessages: string[] = [];
    rules = {
        rangeScore: [
            (value: number | null) => value === null || value >= 1 || 'Value must be at least 1',
            (value: number | null) => value === null || value <= 200 || 'Value must be at most 200'
        ]
    };
    filterDebounce!: NodeJS.Timeout;
    composition: any = []
    composition2: any = []
    uploadingData = false;
    doneUpload = false;
    edit = false;
    newAction = 1;
    companyId = 0;
    countryId = null;
    countryFilterId = null;
    sectorId = null;
    slider = 45
    indexId = null;
    scoreRange = [0, 200]
    performanceRange = [0, 100]
    solidityRange = [0, 100]
    currentDate = "";
    yesterdayDate = "";
    companies = [];
    selectedCompanies: any = [];
    countries = [];
    sectors = [];
    indices = [];
    loadingData = false;
    exist = true;
    update = false;
    scoreMoy = 0;
    soliditeMoy = 0;
    performanceMoy = 0;
    total = 0;
    valo = "";
    startVariation = null;
    menu = false;
    fmp = true;
    operator = null
    totalItems = 0    // Total number of items (from API response)
    totalPages = 0     // Total number of pages (from API response)
    perPage = 50        // Items per page (default value)
    currentPage = 1     // Current page (default value)
    loadingTable = true
    filters = {
        liquidity: {
            value: null,
            operator: '',
        },
        actifs: {
            value: null,
            operator: '',
        },
        passifs: {
            value: null,
            operator: '',
        },
        risques: {
            value: null,
            operator: '',
        },
        rentabilite: {
            value: null,
            operator: '',
        },
        rentabilite_economique: {
            value: null,
            operator: '',
        },
        rentabilite_financiere: {
            value: null,
            operator: '',
        },
        rentabilite_exploitation: {
            value: null,
            operator: '',
        },
    }
    operators = ['<', '<=', '>', '>='];



    endpoint = "company?expand=country,legalTypology,companyActivities.activity.sector&page=${page}&per-page=50&sort=-score"
    get headers() {
        return [
            // { text: "", value: "_actions" },

            { text: this.$i18n.t("models.company.name"), value: "name" },
            {
                text: this.$i18n.t("models.company.country"),
                value: "country.name",
                width: 1,
            },

            {
                text: this.$i18n.t("score"),
                filterable: false,
                value: "score",
            },
            {
                text: this.$i18n.t("performance"),  // New "Close" column
                filterable: false,
                value: "performance_score",

            },
            {
                text: this.$i18n.t("Solidité"),  // New "Nbr Action" column
                filterable: false,
                value: "solidity_score",
            },
        ];
    }

    @Watch("countryFilterId")
    onValueChanged() {
        this.loadIndices(this.countryFilterId);
    }
    @Watch("selected")
    oncheckChanged() {
        console.log("seleced", this.selected)
    }


    @Watch("countryFilterId")
    @Watch("scoreRange")
    @Watch("performanceRange")
    @Watch("solidityRange")
    @Watch("sectorId")
    @Watch("indexId")
    onFilterChanged() {
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);
    }

    // Watchers for filter properties
    @Watch('filters.liquidity.value')
    @Watch('filters.liquidity.operator')
    @Watch('filters.actifs.value')
    @Watch('filters.actifs.operator')
    @Watch('filters.passifs.value')
    @Watch('filters.passifs.operator')
    @Watch('filters.risques.value')
    @Watch('filters.risques.operator')
    @Watch('filters.rentabilite.value')
    @Watch('filters.rentabilite.operator')
    @Watch('filters.rentabilite_economique.value')
    @Watch('filters.rentabilite_economique.operator')
    @Watch('filters.rentabilite_financiere.value')
    @Watch('filters.rentabilite_financiere.operator')
    @Watch('filters.rentabilite_exploitation.value')
    @Watch('filters.rentabilite_exploitation.operator')
    onLiquidityFilterChanged() {
        clearTimeout(this.filterDebounce);
        this.filterDebounce = setTimeout(() => this.loadCompanies(), 300);
    }
    created() {
        setPageTitle("Portfolio Setting ");

        this.loadCompanies();
        this.loadCountries();
        this.loadSectors();
        this.loadIndices();
        this.loadPortfolioInfo();
        // this.$api.get("country?per-page=0").then(j => (this.countries = j.data));
        const today = new Date();
        // Format current date as 'YYYY-MM-DD'
        this.currentDate = this.formatDate(today);

        // Get yesterday's date
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 2); // Subtract 1 day
        // Format yesterday's date as 'YYYY-MM-DD'
        this.yesterdayDate = this.formatDate(yesterday);
    }
    buildFilterString(): string {
        let filterString = '';

        if (this.countryFilterId) {
            filterString += `&filter[country.id]=${this.countryFilterId}`;
        }
        // Handle multiple selected country
        if (this.countryFilterId && this.countryFilterId.length > 0) {
            this.countryFilterId.forEach((id: number) => {
                filterString += `&filter[country.id][in][]=${id}`;
            });
        }
        // Handle multiple selected sectors
        if (this.sectorId && this.sectorId.length > 0) {
            this.sectorId.forEach((id: number) => {
                filterString += `&filter[sector.id][in][]=${id}`;
            });
        }

        if (this.indexId) {
            filterString += `&filter[stockExchange.id]=${this.indexId}`;
        }

        if (this.scoreRange && this.scoreRange.length === 2) {
            filterString += `&filter[company.score][like]=${this.scoreRange.join(',')}`;
        }

        if (this.performanceRange && this.performanceRange.length === 2) {
            filterString += `&filter[company.performance_score][like]=${this.performanceRange.join(',')}`;
        }

        if (this.solidityRange && this.solidityRange.length === 2) {
            filterString += `&filter[company.solidity_score][like]=${this.solidityRange.join(',')}`;
        }
        // Dynamic filter handling
        for (const [filterName, filterData] of Object.entries(this.filters)) {
            // Ensure both value and operator are present before adding the filter
            if (filterData.value !== null && filterData.value !== '' && filterData.operator) {
                // Encode the value and operator
                const encodedValue = encodeURIComponent(filterData.value);
                const encodedOperator = encodeURIComponent(filterData.operator);

                // Construct filter string
                filterString += `&filter[${filterName}][like]=${encodedValue},${encodedOperator}`;
            }
        }
        return filterString;
    }
    truncatedName(name) {
        if (name.length > 50) {
            return name.slice(0, 50) + "...";
        }
        return name;
    }

    loadCompanies() {
        const filterString = this.buildFilterString();

        this.endpoint = `company?expand=country,legalTypology,companyActivities.activity.sector&per-page=50&sort=-score${filterString}`
    }
    // Debounce function to limit the rate of execution
    debounce(func: Function, delay: number) {
        let timeout: number | undefined;
        return (...args: any[]) => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), delay);
        };
    }

    // Utility function to update composition based on selected items
    updateComposition(selectedItems: any[]) {
        const selectedIds = new Set(selectedItems.map(item => item.id));

        // Remove items that are no longer selected
        this.composition = this.composition.filter(comp => selectedIds.has(comp.company_id));

        // Add new selected items that are not already in composition
        const existingIds = new Set(this.composition.map(comp => comp.company_id));
        selectedItems.forEach(item => {
            if (!existingIds.has(item.id)) {
                this.loadComposition(item); // Adjust if needed
            }
        });

        // Debug output
        console.log("Selected Items:", selectedItems);
        console.log("Updated Composition:", this.composition);
    }

    onSelectionChange = this.debounce(({ selectedItems, allItems }: { selectedItems: any[], allItems: any[] }) => {
        this.companies = allItems; // Store the updated companies list
        console.log("this.companies", this.companies);

        // Ensure `selectedItems` is an array
        if (!Array.isArray(selectedItems)) return;

        // Update composition based on selected items
        this.updateComposition(selectedItems);
    }, 1000); // Adjust debounce delay as needed
    loadCountries() {
        return this.$api.get(`country`).then(res => {
            this.countries = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadSectors() {
        return this.$api.get(`sector`).then(res => {
            this.sectors = res.data;
            // console.log("countrys",res.data)
        });
    }
    loadIndices(countryId?: number | null) {
        if (countryId) {
            return this.$api
                .get(`stock-exchange/?&filter[stockExchange.country_id]=${countryId}`)
                .then(res => {
                    this.indices = res.data;
                });
        } else {
            return this.$api.get(`stock-exchange`).then(res => {
                this.indices = res.data;
            });
        }
    }
    onChangeSector(selectedItem: any) {
        console.log("selected sextor", selectedItem);
        if (selectedItem) {
            // this.loadingData = true;
            this.sectorId = selectedItem;
        }
    }
    onChangeCountry(selectedItem: any) {
        console.log("selected country", selectedItem);
        if (selectedItem) {
            // this.loadingData = true;
            this.countryFilterId = selectedItem;
        }
    }

    loadComposition(selectedItem: any) {
        console.log(selectedItem.id)
        this.error = null;
        // this.companyId = 0;
        // this.valo = '';
        // this.composition = {};
        // this.countryId = null;
        const lang = this.$i18n.locale;
        if (selectedItem) {
            this.loadingData = true;
            const selectedCompany = this.companies.find(
                company => company.id === selectedItem.id
            );
            console.log("selectedCompany:", selectedCompany)
            console.log("selectedCompany: selectedItem", selectedItem)

            console.log("Available companies:", this.companies);
            console.log("selectedCompanyname:", selectedItem.country.name[lang])

            // this.countryId = selectedCompany.country.name[lang];
            // this.composition.company_id = selectedCompany.id;
            // this.composition.valo = 0;
            const company_currency = selectedItem.profil_fmp.currency;
            console.log("company_currency", company_currency)
            if (selectedItem.origin == "FMP") {
                this.fmp = true;
            } else {
                this.fmp = false;
            }
            console.log("this.composition", this.composition)
            this.$api
                .get(
                    `portfolio/company-info?id=${selectedItem.id}&devise=${company_currency}&portfolioDevise=${this.portfolioDevise}`
                )
                .then(res => {
                    this.composition.push({
                        company_id: selectedItem.id,
                        name: selectedItem.name,
                        close: res.data.close,
                        score: res.data.score ? res.data.score.global.toFixed(3) : null,
                        solidite: res.data.score ? res.data.score.A1.toFixed(3) : null,
                        performance: res.data.score ? res.data.score.A2.toFixed(3) : null,
                        country: selectedItem.country.name[lang],
                        valo: 0,
                        company_currency: company_currency
                    });
                    // console.log("composition", this.composition)

                    // this.composition.close = res.data.close;
                    // if (res.data.score) {
                    //     this.composition.score = res.data.score.global.toFixed(3);
                    //     this.composition.solidite = res.data.score.A1.toFixed(3);
                    //     this.composition.performance = res.data.score.A2.toFixed(3);
                    // }

                    this.loadingData = false;
                })
                .catch(error => {
                    this.loadingData = false;
                    console.error("Error loading company info:", error);
                });
            console.log("this.composition after", this.composition)

        }
    }

    addCompany(selectedCompany, index) {
        console.log("selectedCompany ", selectedCompany)
        // console.log("this.companies ", this.companies)
        // const selectedCompany = this.companies.find(
        //     company => company.id === companyId
        // );
        console.log("selectedCompanies ", this.selectedCompanies)

        // console.log("selectedCompnay add", selectedCompany)
        // Check if the company with the same ID already exists in selectedCompanies
        const exists = this.selectedCompanies.find(
            comp => Number(comp.id) === Number(selectedCompany.company_id)
        );
        console.log("exists add", exists)

        this.doneUpload = false;
        console.log(
            "exists ",
            this.selectedCompanies,
            selectedCompany,
            exists,
            this.companyId
        );
        // If the company doesn't exist in selectedCompanies, add it
        if (selectedCompany && !exists) {
            this.soliditeMoy = 0;
            this.performanceMoy = 0;
            // upadte total's
            this.total = (
                parseFloat(this.composition[index].valo) + parseFloat(this.total)
            ).toFixed(3);
            // add new line
            console.log("selectedCompany.  name", selectedCompany.name)
            this.selectedCompanies.push({
                id: selectedCompany.company_id,
                name: selectedCompany.name,
                symbol: selectedCompany.symbol,
                nbraction: this.composition[index].nbr_action,
                close: this.composition[index].close,
                valo: this.composition[index].valo,
                global: this.composition[index].score,
                solidite: this.composition[index].solidite,
                performance: this.composition[index].performance,
                devise: this.composition[index].company_currency,
                percentage: this.composition[index].percentage,
                editing: false,
            });

            this.selectedCompanies.map(item => {
                item.percentage = ((parseFloat(item.valo) / this.total) * 100).toFixed(
                    2
                );

                this.soliditeMoy =
                    parseFloat(this.soliditeMoy) +
                    parseFloat(item.solidite * item.percentage) / 100;
                this.performanceMoy =
                    parseFloat(this.performanceMoy) +
                    parseFloat(item.performance * item.percentage) / 100;
            });

            this.scoreMoy =
                parseFloat(this.soliditeMoy) + parseFloat(this.performanceMoy);
        }
        // else{
        //     this.error = {msg : 'company exist deja'};
        // }

        // this.companyId = 0;
        // this.valo = "";
        // this.composition = {};
        // this.countryId = null;

        console.log("jannt", this.selectedCompanies);
    }
    deleteCompany(id: any) {
        this.doneUpload = false;
        this.$root.$confirm
            .open(
                "Delete company",
                "Are you sure you want to delete this company ?",
                {}
            )
            .then(confirm => {
                if (confirm) {
                    this.selectedCompanies = this.selectedCompanies.filter(
                        company => company.id !== id
                    );
                    if (this.selectedCompanies.length > 0) {
                        this.calculate();
                    }
                }
            });
    }
    editNbrAction(comp: any) {
        // Check if newAction is a valid number
        const parsedAction = parseFloat(comp.nbraction);
        if (isNaN(parsedAction) || parsedAction <= 0) {
            this.error = { msg: "invalid action" };
            return;
        }

        // Update the nbraction for the specific company
        this.selectedCompanies.forEach(item => {
            if (item.id === comp.id) {
                item.nbraction = parsedAction;
                item.editing = false; // Exit editing mode for this company
            }
        });

        // Recalculate any necessary values
        this.calculate();
    }
    editCompany(id: any) {
        this.selectedCompanies.forEach(item => {
            item.editing = item.id === id;
        });
    }
    async updateNbrAction(index) {
        let action = this.composition[index].nbr_action;
        // if(this.composition.company_currency != this.portfolioDevise){
        //     const response =  await fetch('https://financialmodelingprep.com/api/v3/historical-chart/4hour/'+this.composition.company_currency+''+this.portfolioDevise+'?from='+this.yesterdayDate+'&to='+this.currentDate+'&apikey=016fd8297eb7a8be6bc3fdf9cc65240d');
        //     const data = await response.json();
        //     if(data.length > 0)
        //         convert = data[0].close;
        // }

        this.composition[index].valo = parseFloat(action * this.composition[index].close).toFixed(
            3
        );
        // this.valo = this.composition.valo;
    }
    uploadData() {
        this.uploadingData = true;
        this.update = false;
        let url = `portfolio/integrate-version?portfolio=${this.portfolioId}`;

        if (this.exist == false && this.startVariation)
            url = `portfolio/integrate-version?portfolio=${this.portfolioId}&dateDebut=${this.startVariation}`;

        console.log("eeeee", url, this.startVariation, this.exist);
        this.$api
            .post(url, {
                composition: this.selectedCompanies,
            })
            .then(response => {
                this.uploadingData = false;
                this.doneUpload = true;
                window.location.reload();
            })
            .catch(e => {
                this.error = e.response.data;
                this.uploadingData = false;
            })
            .finally(() => {
                this.doneUpload = true;
                //window.location.reload();
            });
    }

    loadPortfolioInfo() {
        return this.$api
            .get(
                `portfolio/${this.portfolioId}?expand=portfolioVersions.portfolioComposition.company`
            )
            .then(res => {
                if (
                    res.data.portfolioVersions &&
                    res.data.portfolioVersions.length > 0
                ) {
                    const lastPortfolioVersion = res.data.portfolioVersions.slice(-1)[0];

                    // Map the portfolioComposition of the last version to selectedCompanies
                    this.selectedCompanies = lastPortfolioVersion.portfolioComposition.map(
                        item => ({
                            id: item.company_id,
                            name: item.company.name,
                            symbol: item.company.symbol,
                            nbraction: item.nbr_action,
                            close: parseFloat(item.close).toFixed(3),
                            valo: parseFloat(item.valo).toFixed(3),
                            global: parseFloat(item.score).toFixed(3),
                            solidite: parseFloat(item.solidite).toFixed(3),
                            performance: parseFloat(item.performance).toFixed(3),
                            devise: item.company_currency,
                            percentage: parseFloat(item.percentage).toFixed(2),
                            editing: false,
                        })
                    );

                    this.selectedCompanies.map(itemx => {
                        this.total = parseFloat(itemx.valo) + parseFloat(this.total);
                        this.soliditeMoy =
                            parseFloat(this.soliditeMoy) +
                            parseFloat(itemx.solidite * itemx.percentage) / 100;
                        this.performanceMoy =
                            parseFloat(this.performanceMoy) +
                            parseFloat(itemx.performance * itemx.percentage) / 100;
                    });

                    this.scoreMoy =
                        parseFloat(this.soliditeMoy) + parseFloat(this.performanceMoy);
                    this.exist = true;
                } else {
                    // Handle case where portfolioVersions is empty
                    this.selectedCompanies = [];
                    this.exist = false;
                }
            });
    }

    calculate() {
        this.total = 0;
        this.soliditeMoy = 0;
        this.performanceMoy = 0;
        this.selectedCompanies.map(item => {
            item.valo = (parseFloat(item.close) * parseFloat(item.nbraction)).toFixed(
                3
            );
            this.total = parseFloat(item.valo) + parseFloat(this.total);
        });
        this.selectedCompanies.map(itemx => {
            itemx.percentage = ((parseFloat(itemx.valo) / this.total) * 100).toFixed(
                2
            );
            this.soliditeMoy =
                parseFloat(this.soliditeMoy) +
                parseFloat(itemx.solidite * itemx.percentage) / 100;
            this.performanceMoy =
                parseFloat(this.performanceMoy) +
                parseFloat(itemx.performance * itemx.percentage) / 100;
        });

        this.scoreMoy =
            parseFloat(this.soliditeMoy) + parseFloat(this.performanceMoy);
        this.error = null;
    }

    formatDate(date: any) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    viewCompany(id) {
        const routePath = `/company/${id}`;
        // Use window.open to open the route in a new tab
        window.open(routePath, "_blank");
        //this.$router.push(`/company/${id}`);
    }

    viewIndex() {
        const routePath = `/portfolio/${this.portfolioId}`;
        window.open(routePath, "_blank");
    }
}
